<template>
  <div class="body">
    <el-container class="container">
      <el-header height="45px">
        <p class="title">点击查看历史咨询记录</p>
      </el-header>
    <el-main style="overflow-y:auto;">
      <div style="text-align:center;">
        <img src='@/assets/youku_logo.png' style="height:36px;width:36px;" />
        <p>小优</p>
      </div>
      <div style="width:100%;text-align:right;">
        <div class="avtar_dialog" @click="dialogshow1 = true" v-html="user_html"></div>
      </div>
      <div style="width:100%;text-align:left;">
        <img src="@/assets/youku_kefu.png" style="float:left" />
        <div class="service_dialog" @click="dialogshow2 = true" v-html="service_html" ref="service"></div>
      </div>
    </el-main>
    <el-footer height="95px" style="padding:0;">
      <img src="@/assets/youku_footer.png">
    </el-footer>
    </el-container>
  <el-dialog
  top="16vh"
  :visible.sync="dialogshow1"
  width="550px"
  title="用户对话内容"
  center
  :before-close="handleClose">
  <el-input 
  type="textarea"
  :rows="6"
  placeholder="请输入用户对话内容"
  v-model="user_text"></el-input>
  <span slot="footer" class="dialog-footer">
    <el-button @click="user_confirm()" type="primary">确定</el-button>
  </span>
</el-dialog>
<el-dialog
  top="16vh"
  :visible.sync="dialogshow2"
  width="550px"
  title="优酷客服对话内容"
  center
  :before-close="handleClose">
  <el-input 
  type="textarea"
  :rows="6"
  placeholder="请输入优酷客服对话内容"
  v-model="service_text"></el-input>
  <span slot="footer" class="dialog-footer">
    <el-button @click="service_confirm()" type="primary">确定</el-button>
  </span>
</el-dialog>
  </div>
</template>

<script>
export default {
  name: "iqiyi",
  data() {
    return {
      user_text: "",
      service_text:"",
      user_html: "",
      service_html:"",
      dialogshow1:false,
      dialogshow2:false,
    };
  },
  mounted() {
    
  },
  methods: {
  handleClose(done) {
            done();
      },
  user_confirm(){
    this.user_html=""
    var user =this.user_text.split(/[(\r\n)\r\n]+/);
    for(let i in user){
      this.user_html+="<p>"+user[i]+"</p>"
    }
    this.dialogshow1 = false ;
  },
  service_confirm(){
    this.service_html=""
    var service =this.service_text.split(/[(\r\n)\r\n]+/);
    for(let i in service){
      this.service_html+="<p>"+service[i]+"</p>"
    }
    this.dialogshow2 = false ;
  },
  clearborder(){
    this.dialogshow2 = true
  }
  },
};
</script>
<style lang="less" scoped>
body {
  width: 100%;
  
}
.set {
  color: #3743d6;
  cursor: pointer;
  width: 100px;
}
.el-header {
  padding: 0;
}
.title{color:#3399FF;text-align:center;cursor: pointer;font-size: 14px;height: 40px;line-height: 40px;}
.container {
  background-color: #f5f5f5;
  height: 700px;
  width: 960px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.el-main {
  position: relative;
  height: 100%;
  z-index: 100;
  padding-left: 0px;
  padding-right: 0;
  border-left: 1px solid #e2e2e2;
  overflow: initial;
}
.el-input {
  width: 300px;
  margin-left: 20px;
}
.body {
  width: 100%;
  height: 930px;
  position: absolute;
  background: url("../../assets/youku_bg.jpg") 0 0 no-repeat;
  background-size:cover;
}
.avtar_dialog{min-width: 100px;max-width: 650px; min-height:40px;background-color: #24A5FF;display: inline-block;margin-top: 25px;
border-radius: 5px 5px 5px 5px;color:#ffffff;text-align: left;line-height: 25px;font-size: 14px;padding: 10px 14px;margin-right:26px;
font-family: "\5FAE\8F6F\96C5\9ED1","\5B8B\4F53",Arial,Helvetica,sans-serif;cursor: pointer;position: relative;
}
.service_dialog{min-width: 100px;max-width: 650px; min-height:40px;background-color: #fff;display: inline-block;margin-top: 10px;
border-radius: 4px;color:#333;text-align: left;line-height: 25px;font-size: 14px;padding: 10px 14px;margin-left: 15px;
font-family: "\5FAE\8F6F\96C5\9ED1","\5B8B\4F53",Arial,Helvetica,sans-serif;cursor: pointer;box-shadow: 0 0 3px 0 rgb(200,200,200,0.1);
>p{margin-top:20px;}
}
.vip{position: absolute;width: 11px;height: 11px;display: block;}
</style>